export enum CustomerExperienceChatMessageTypeEnum {
    UserMessage = 0,
    AgentMessage = 1,
    TicketLog = 2,
    PrivateNote = 3
}

export enum CustomerExperienceChatMessageTypeStringEnum {
    UserMessage = 'UserMessage',
    AgentMessage = 'AgentMessage',
    TicketLog = 'TicketLog',
    PrivateNote = 'PrivateNote'
}

export enum CustomerExperienceChatMessageType {
    Text = 0,
    Image = 1,
    Document = 2,
    Video = 3,
    Sticker = 4,
    Interactive = 5,
    Quick_Reply = 6,
    QuickReplyImageVideo = 7,
    QuickReplyFile = 8,
    Location = 9,
    Contact = 10
}
